import React, { useState } from "react";
import { connect } from "react-redux";

import "./../styles/NewsPageStyles.css";
import ButtonBoxComponent from "./ButtonBoxComponent";

const NewsPage = props => {
	const [renderNews, setRenderNews] = useState(false);
	const [newsToRender, setNewsToRender] = useState([]);

	const renderPreviewText = e => {
		const min = 80;
		let charCount = 0;
		let previewText = "";

		let words = e.split(" ");
		for (let i = 0; i < words.length; i++) {
			charCount = charCount + words[i].length;
			previewText = previewText + " " + words[i];
			if (charCount > min) {
				break;
			}
		}
		return previewText;
	};

	const renderIsTrue = n2 => {
		if (n2.id !== newsToRender.id) {
			return (
				<div>
					<h2 className="news-box-header">{n2.title}</h2>
					<button
						onClick={() => {
							setRenderNews(true);
							setNewsToRender(n2);
						}}
						className="read-more-button"
					>
						{renderPreviewText(n2.text)}
					</button>
				</div>
			);
		} else {
			return renderReadMoreNews(n2);
		}
	};
	const renderReadMoreNews = n3 => {
		let paragraphs = n3.text.split("*/d");

		return (
			<div className="news-box">
				<h2 className="news-box-header-rendered">{n3.title}</h2>
				{paragraphs.map(p => {
					return <p className="news-text">{p}</p>;
				})}
				{newsToRender.media !== undefined ? (
					<img src={newsToRender.media} className="news-image" />
				) : (
					""
				)}
				<button
					onClick={() => {
						setRenderNews(false);
						setNewsToRender([]);
					}}
					className="read-more-button"
				>
					{<p className="show-less-text">{"Dölj <<"}</p>}
				</button>
			</div>
		);
	};

	return (
		<div className="newspage-main">
			<div className="news-section">
				<h1>Nyheter</h1>
				{renderNews === false
					? props.news.map(n => {
							return (
								<div className="news-box">
									<h2 className="news-box-header">{n.title}</h2>
									<button
										onClick={() => {
											setRenderNews(true);
											setNewsToRender(n);
										}}
										className="read-more-button"
									>
										<div>
											<p>
												{/*Skickar först in texten till metoden som tar fram en preview
												Sedan lägger vi till punkter och avslutar med en läs mer - knapp
												som är i fetstil */}
												{renderPreviewText(n.text)}
												{"..."}{" "}
												<span className="read-more-words"> {"läs mer >>"}</span>
											</p>
										</div>
									</button>
								</div>
							);
					  })
					: props.news.map(n2 => renderIsTrue(n2))}
			</div>
			<ButtonBoxComponent />
		</div>
	);
};

const mapStateToProps = state => ({
	news: state.news
});

export default connect(mapStateToProps)(NewsPage);

{
	/* Conditional rendering picture
						{e.media !== undefined ? (
							<img src={e.media} className="news-image" />
						) : (
							""
						)} */
}

{
	/* Fungerande utan radbrytningar osv tis 10:06

	const NewsPage = props => {
	const [renderNews, setRenderNews] = useState(false);
	const [newsToRender, setNewsToRender] = useState([]);

	const renderPreviewText = e => {
		const min = 80;
		let charCount = 0;
		let previewText = "";

		let words = e.split(" ");
		for (let i = 0; i < words.length; i++) {
			charCount = charCount + words[i].length;
			previewText = previewText + " " + words[i];
			if (charCount > min) {
				break;
			}
		}
		return previewText + " läs mer >>";
	};

	const renderIsTrue = n2 => {
		if (n2.id !== newsToRender.id) {
			return (
				<div>
					<h2 className="news-box-header">{n2.title}</h2>
					<button
						onClick={() => {
							setRenderNews(true);
							setNewsToRender(n2);
						}}
						className="read-more-button"
					>
						{renderPreviewText(n2.text)}
					</button>
				</div>
			);
		} else {
			return (
				<div className="news-box">
					<h2 className="news-box-header-rendered">{n2.title}</h2>
					<p className="news-text">{newsToRender.text}</p>
					{newsToRender.media !== undefined ? (
						<img src={newsToRender.media} className="news-image" />
					) : (
						""
					)}
					<button
						onClick={() => {
							setRenderNews(false);
							setNewsToRender([]);
						}}
						className="read-more-button"
					>
						{" "}
						{"Dölj <<"}
					</button>
				</div>
			);
		}
	};

	return (
		<div className="newspage-main">
			<div className="news-section">
				<h1>Nyheter</h1>
				{renderNews === false
					? props.news.map(n => {
							return (
								<div className="news-box">
									<h2 className="news-box-header">{n.title}</h2>
									<button
										onClick={() => {
											setRenderNews(true);
											setNewsToRender(n);
										}}
										className="read-more-button"
									>
										{renderPreviewText(n.text)}
									</button>
								</div>
							);
					  })
					: props.news.map(n2 => renderIsTrue(n2))}
			</div>
			<ButtonBoxComponent />
		</div>
	);
};

const mapStateToProps = state => ({
	news: state.news
});

export default connect(mapStateToProps)(NewsPage);

	*/
}
