import React from "react";
import  "../styles/MainStyles.css";
import ButtonBoxComponent from "./ButtonBoxComponent";

const MainPage = () => {
	return (
		<div className="main-background">
			<section className="main-info-section">
				<h1 className="main-title">
					Varmt välkommen till miljö.se och Myllan!
				</h1>
				<p className="main-text">
					Myllan arbetar inom ett brett spektra av verksamheter med inriktning
					mot miljö, samhälle samt hälsa och livsstil. Vårt engagemang är
					uppbyggt på olika sätt kopplat till de olika verksamheterna -
					gemensamt är att vi arbetar med ett brinnande intresse, driv och
					affärsmässighet.
				</p>
				<p className="main-text">
					Vi beaktar aktuella strömningar och blickar gärna framåt. Självklart
					för oss är långsiktiga relationer med våra kunder, samarbetspartners
					och övriga kontakter.
				</p>

				<p className="main-text">
					Vi ska förklara mer framöver vart eftersom vår verksamhet utvecklas…
					och återigen, varmt välkommen!{" "}
				</p>
				<img src="forslag_logga_14.png" className="main-page-logga"></img>
			</section>
			<ButtonBoxComponent />
		</div>
	);
};

export default MainPage;
