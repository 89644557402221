import React, { useState } from "react";
import axios from "axios";

import "./../styles/FotoArkivStyles.css";
import ViewFolders from "./Reusable/ViewFolders";

const FotoArkiv = () => {
	const [albumYear, setAlbumYear] = useState(0);
	const [activeAlbums, setActiveAlbums] = useState([]);

	const albumClicked = (e) => {
		let year = e;
		if (year === albumYear) {
			setActiveAlbums([])
			setAlbumYear(0)
		} else {
			setAlbumYear(year)
			setActiveAlbums([])
			axios
				.get(`https://react-myllan-frontend.herokuapp.com/photoFolders/${year}`)
				//.get(`http://localhost:5000/photoFolders/${year}`)

				.then((result) => {
					console.log(result.data);
					setActiveAlbums(result.data);
					console.log("CDM then");
				})
				.catch((err) => console.log(err));
		}
	};

	return (
		<div className="fotoarkiv-main">
			<div className="fotoarkiv-text">
				<h1>Fotoarkiv</h1>
				<p>
					Vi uppdaterar löpande vårt fotoarkiv med alltifrån naturfotografier
					till cykeltävlingar.
				</p>
				<p>
					För närvarande finns det tillgängliga album från följande år:	
				</p>
			</div>
			<div className="fotoarkiv-år">
				<div className="fotoarkiv-år-cirkel" onClick={() => albumClicked(2020)}>
					<div className="fotoarkiv-cirkel-text">2020</div>
				</div>
				<div className="fotoarkiv-år-cirkel" onClick={() => albumClicked(2021)}>
					<div className="fotoarkiv-cirkel-text">2021</div>
				</div>
			</div>

			{activeAlbums.length !== 0 ? <ViewFolders albums={activeAlbums} /> : ""}
		</div>
	);
};

export default FotoArkiv;
