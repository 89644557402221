import React, { useState } from "react";
import axios from "axios";

const SlideShowImages = () => {
	const [slideShowImage, uploadSlideShowImage] = useState("");

	const fileChosen = e => {
		let upLoadedFile = e.target.files[0];
		uploadSlideShowImage(upLoadedFile);
	};

	const upLoadClicked = () => {
		console.log(slideShowImage);
		const data = new FormData();
		data.append("imageName", slideShowImage);
		console.log(data)
		axios
			.post("http://localhost:5000/imagesSlideShow/upload", data)
			.then(response => {
				console.log(response);
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<div>
			<h3>Ladda upp bild till bildspel</h3>
			<input type="file" onChange={fileChosen} />
			<button onClick={upLoadClicked}>upload</button>
		</div>
	);
};

export default SlideShowImages;
