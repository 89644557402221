import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { connect } from "react-redux";
import axios from "axios";
import "./../styles/MyllanFotoStyles.css";
import { SocialIcon } from "react-social-icons";
import { NavLink } from "react-router-dom";



import ButtonBoxComponent from "./ButtonBoxComponent";
import SeeAllFolders from "./SeeAllFolders";

function MyllanFoto(props) {
	const [images, setImages] = useState([]);
	const [viewAlbums, toggleViewAlbums] = useState(false);
	const [viewExampleImages, toggleViewExampleImages] = useState(true);

	const fixImageArray = (imageArray) => {
		let retArray = [];
		imageArray.map((image) => {
			retArray.push({
				original: "https://storage.googleapis.com/" + image.slideShowImage,
				thumbnail: "https://storage.googleapis.com/" + image.slideShowImage,
			});
		});
		setImages(retArray);
		console.log("images is: ", images);
	};

	const request = () => {
		console.log("req called");
		axios
			.get("http://localhost:5000/imagesSlideShow/")
			.then((result) => fixImageArray(result.data.slideShowImages));
	};

	const viewAlbumsClicked = () => {
		viewAlbums === false ? toggleViewAlbums(true) : toggleViewAlbums(false);
	};

	return (
		<div className="myllan-foto-main">
			<div className="myllan-foto-content-section">
				<div className="myllan-foto-textsection">
					<h1>Myllan Foto</h1>
					<p>
						Myllan Foto arbetar med olika typer av projekt inom fotografering.
						Vi har en bred kunskap och utför våra arbeten med stort engagemang.
					</p>
					<p>
						Exempel på områden vi arbetar med kan vara natur, landskap, djur,
						porträtt, stilleben, stadsmiljöer, händelser där vi helst använder
						oss av naturligt ljus. Vi arbetar också branschspecifikt inom t ex
						fastighet, industri, bygg och miljö. Vidare fotograferar vi gärna
						sport, djur, familj och resor m.m.
					</p>
					<p>Vi uppdaterar löpande vårt <NavLink to="/fotoarkiv">fotoarkiv!</NavLink></p>
					<SocialIcon
						url="https://www.facebook.com/MyllanFoto"
						target="_blank"
                        className="facebook-icon"
					/>
					<SocialIcon
						url="https://www.instagram.com/myllan_foto/"
						target="_blank"
                        className="facebook-icon icon-bar"

					/>
					<p>Varmt välkommen att kontakta oss!</p>
				</div>
				<div className="image-section">
					<h2>Ett litet urval av fotografier</h2>
					<div className="image-gallery">
						<ImageGallery
							items={props.images}
							useBrowserFullscreen={false}
							disableArrowKeys={false}
						/>
					</div>
				</div>
			</div>
			<ButtonBoxComponent currentPage={"myllanFoto"} />
		</div>
	);
}

const mapStateToProps = state => ({
	images: state.images
});

export default connect(mapStateToProps)(MyllanFoto);
