import React from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import Jimp from "jimp";

import "react-datepicker/dist/react-datepicker.css";

class Photos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			folders: [],
			selectedFolder: null,
			name: "",
			description: "",
			tags: "",
			date: new Date(),
			photographer: "Johan Ericsson", //Temp
			price: "",
			photos: [],
			folderId: "", //Exists
			// folderId: "4j10234j234" // Does not exist
			uploading: false,
		};
	}

	fileChosen = async (e) => {
		e.preventDefault();
		console.log(e.target.files[0]);
		this.setState({
			photos: e.target.files,
		});
	};

	componentDidMount = () => {
		console.log("CDM");
		axios
			.get("https://react-myllan-frontend.herokuapp.com/photoFolders")
			.then((result) => {
				console.log(result.data);
				this.setState({ folders: result.data });
				console.log("CDM then");
			})
			.catch((err) => console.log(err));
		console.log("CDM end");
	};

	changePhotoSize = (photo) => {
		return photo;
	};

	upLoadClicked = async () => {
		this.setState({
			uploading: true,
		});
		let retTags = this.state.tags.split(" ");
		console.log(this.state);
		for (let i = 0; i < this.state.photos.length; i++) {
			//let photo = await this.fixPhotoSize(this.state.photos[i]);
			let data = new FormData();
			console.log(this.state.photos[i])
			data.append("photo", this.state.photos[i]);
			data.append("name", this.state.name);
			data.append("description", this.state.description);
			data.append("tags", retTags);
			data.append("date", this.state.date);
			data.append("photographer", this.state.photographer);
			data.append("price", this.state.price);
			data.append("folderId", this.state.selectedFolder._id);
			// Laddar upp
			axios
				.post("https://react-myllan-frontend.herokuapp.com/photos/upload", data)
				.then((response) => {
					console.log("response: " + response);
					this.setState({
						folders: [],
						selectedFolder: null,
						name: "",
						description: "",
						tags: "",
						date: new Date(),
						photographer: "Johan Ericsson", //Temp
						price: "",
						photos: [],
						folderId: "", //Exists
						// folderId: "4j10234j234" // Does not exist
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
		this.setState({
			uploading: false,
		});
	};

	handleNameChange = (e) => {
		this.setState({ name: e.target.value });
	};

	handleDescriptionChange = (e) => {
		this.setState({ description: e.target.value });
	};

	handleTagChange = (e) => {
		this.setState({ tags: e.target.value });
	};

	handleDateChange = (e) => {
		this.setState({
			date: e,
		});
	};

	handlePriceChange = (e) => {
		this.setState({
			price: e.target.value,
		});
	};

	handleSelectFolder = (e) => {
		console.log(e.target.value);
		this.setState({
			selectedFolder: JSON.parse(e.target.value),
		});
		console.log("selected " + this.state.selectedFolder);
	};

	render() {
		return (
			<div>
				{this.state.uploading === false ? (
					<div>
						<h3>Upload Photo</h3>
						<form>
							<select onChange={this.handleSelectFolder}>
								<option selected disabled>
									Välj mapp...
								</option>
								{this.state.folders.map((folder) => {
									return (
										<option key={folder._id} value={JSON.stringify(folder)}>
											{folder.name}
										</option>
									);
								})}
							</select>
							<input
								type="text"
								value={this.state.name}
								onChange={this.handleNameChange}
								placeholder={"Lägg till namn"}
							/>
							<textarea
								placeholder={"lägg till beskrivning"}
								onChange={this.handleDescriptionChange}
								value={this.state.description}
							/>
							<textarea
								placeholder={"lägg till taggar..."}
								onChange={this.handleTagChange}
								value={this.state.tags}
							/>
							<DatePicker
								selected={this.state.date}
								onChange={this.handleDateChange}
							/>
							<input
								type="text"
								value={this.state.price}
								onChange={this.handlePriceChange}
								placeholder="lägg till pris"
							/>
						</form>
						<input type="file" multiple onChange={this.fileChosen} />
						<button onClick={this.upLoadClicked}>upload</button>{" "}
						<button onClick={() => console.log(this.state)}>TEST</button>
						<img
							src={
								"https://storage.googleapis.com/myllan-mern-11/89156175_3125707037463758_536298727866892288_n.jpg"
							}
						/>
					</div>
				) : (
					<div className="sweet-loading">
						<p>UPLOADING</p>
					</div>
				)}
			</div>
		);
	}
}

export default Photos;

/*fixPhotoSize = (photo) => {
		let fixedPhotos = [];
		const name = photo.name;
		const objectURL = URL.createObjectURL(photo);
		console.log(objectURL);
		return new Promise((resolve, reject) => {
			Jimp.read(objectURL, (err, image) => {
				if (err) throw err;
				const width = image.bitmap.width;
				const height = image.bitmap.height;
				console.log("width: " + width + ", height: " + height);
				let img;
				if (width / height > 1) {
					//Bilden är liggandes
					image
						.resize(1280, Jimp.AUTO)
						.quality(90)
						.getBase64(Jimp.MIME_JPEG, function(err, src) {
							img = new File([src], name, {type: "image/jpeg"})
						})
				} else {
					image
						.resize(Jimp.AUTO, 1280)
						.getBase64(Jimp.MIME_JPEG, function(err, src) {
							img = src
						})
				}
				console.log("new image: ");
				console.log(
					"width: " + image.bitmap.width + ", height: " + image.bitmap.height
				);
				console.log(img);
				//let fixed_image = new File([image.getBuffer], name);
				let fixed_image = new File([buffer.toString("base64")], name, {
					type: "image/jpeg",
				});
				fixedPhotos.push(img);
				if (fixedPhotos.length !== 0) {
					console.log("success");
					resolve(fixedPhotos[0]);
				} else {
					reject("No");
				}
			});
		});
	}; */
