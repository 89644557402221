import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect'

import Header from "./components/Header";
import MainPage from "./components/MainPage";
import CyklandeVanner from "./components/CyklandeVanner";
import MyllanFoto from "./components/MyllanFoto";
import HomePage from "./components/HomePage";
import ContactPage from "./components/ContactPage";
import NewsPage from "./components/NewsPage";
import MyllanIT from "./components/MyllanIT";
import Footer from "./components/Footer";
import AdminPage from "./components/AdminComponents/AdminPage";
import FotoArkiv from "./components/FotoArkiv"
import PhotoAlbums from "./components/PhotoAlbums";
import ViewFolder from "./components/Reusable/ViewFolder"
import ViewImage from "./components/Reusable/ViewImage"

import "./styles/Shared.css";
import 'react-bnb-gallery/dist/style.css'



class App extends Component {
	render() {
		return (
			<HttpsRedirect>
				<BrowserRouter>
					<div className="App-background">
						<Header />
						<div>
							<Switch>
								<Route path="/" component={MainPage} exact={true} />
								<Route path="/cyklandevanner" component={CyklandeVanner} />
								<Route path="/myllanfoto" component={MyllanFoto} />
								<Route path="/fotoarkiv" component={FotoArkiv} />
								<Route path="/home" component={HomePage} />
								<Route path="/contact" component={ContactPage} />
								<Route path="/news" component={NewsPage} />
								<Route path="/myllanIT" component={MyllanIT} />
								<Route path="/adminpage" component={AdminPage} />
								<Route path="/photoAlbums" component={PhotoAlbums} />
								<Route path="/view/:folderID" component={ViewFolder}/>
								<Route path="/viewImage/:imageID" component={ViewImage} />
							</Switch>
						</div>
						<Footer />
					</div>
				</BrowserRouter>
			</HttpsRedirect>
		);
	}
}

export default App;
