import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactBnbGallery from "react-bnb-gallery";
import ButtonBoxComponent from "./../ButtonBoxComponent";
import Gallery from "react-grid-gallery";
import Jimp from "jimp";

import "./../../styles/ViewFolderStyles.css";
import ViewImage from "./ViewImage";

const ViewFolder = (props) => {
	const [photos, setPhotos] = useState([]);
	const [slideShowImages, setSlideShowImages] = useState([]);
	const [show, toggleShow] = useState(false);
	const [galleryImages, setGalleryImages] = useState([]);
	const [imageToView, setImageToView] = useState("");

	const folderId = props.match.params.folderID;

	useEffect(() => {
		document.addEventListener("keydown", keyDownFunc, false);

		axios
			.get(`https://react-myllan-frontend.herokuapp.com/photos/${folderId}`)
			//.get(`http://localhost:5000/photos/${folderId}`)
			// Här ska vi göra flera get calls egentligen för att också hämta namnet på foldern
			// ^ detta görs enligt: https://github.com/axios/axios/issues/371
			.then((result) => {
				setPhotos(result.data);
				fixSlideShow(result.data);
				fixGallery(result.data);
			})
			.catch((err) => console.log(err));
	}, []);

	const keyDownFunc = (e) => {
		if (e.keyCode === 27) {
			setImageToView("");
		}
	};

	const fixSlideShow = (images) => {
		if (images.length > 0) {
			let imageArray = [];
			for (let i = 0; i < images.length; i++) {
				let image = {
					photo: `https://storage.googleapis.com/${images[i].photoName}`,
					thumbnail: `https://storage.googleapis.com/${images[i].photoName}`,
					number: i,
				};
				imageArray.push(image);
			}
			setSlideShowImages(imageArray);
		}
	};

	const fixGallery = async (images) => {
		let imagesToPush = [];
		console.log(images)
		for (let i = 0; i < images.length; i++) {
			//console.log(images[i]);
			let src = `https://storage.googleapis.com/${images[i].photoName}`;
			let imgStyle = {};
			if (images[i].thumbnailWidth !== "auto") {
				imgStyle = {
					width: images[i].thumbnailWidth + "px",
					height: images[i].thumbnailHeight,
				};
			} else if (images[i].thumbnailHeight !== "auto") {
				imgStyle = {
					height: images[i].thumbnailHeight + "px",
					width: images[i].thumbnailWidth,
				};
			} else {
				imgStyle = {
					width: images[i].thumbnailWidth + "px",
					height: images[i].thumbnailHeight + "px",
				};
			}
			let img = {
				src: src,
				imgStyle: imgStyle,
			};

			imagesToPush.push(img);
		}
		console.log(imagesToPush)
		setGalleryImages(imagesToPush);
	};

	const imageClicked = (src = "") => {
		console.log("src: " + src)
		if (
			imageToView === undefined ||
			imageToView === "" ||
			imageToView !== src
		) {
			console.log("h")
			setImageToView(src);
		} else {
			console.log("fasdf")
			setImageToView("");
		}
		console.log(imageToView)
	};

	/* TODO: */
	const backgroundClicked = () => {
		//console.log("uiadnos")
		//setImageToView("");
	};

	return (
		<div className="view-folder-container">
			<div className="view-folder-content">
				<h1>{props.location.state.name}</h1>
				<button
					className="show-slideshow-button"
					onClick={() => toggleShow(true)}
				>
					Visa som bildspel
				</button>
				<div className="bnb-gallery">
					<ReactBnbGallery
						backgroundColor={"7E7E7E"}
						show={show}
						photos={slideShowImages}
						onClose={() => toggleShow(false)}
					/>
				</div>
				{galleryImages.length !== 0 ? (
					<div
						className={
							imageToView.length > 0
								? "image-gallery-folder active-image"
								: "image-gallery-folder"
						}
						onClick={() => backgroundClicked()}
					>
						{galleryImages.map((image) => {
							{
								return (
									<button
										className="image-button-view-folder"
										onClick={() => imageClicked(image.src)}
									>
										<img src={image.src} style={image.imgStyle} />
									</button>
								);
							}
						})}
					</div>
				) : (
					<p>LADDAR</p>
				)}
				{imageToView !== "" ? (
					<button
						className="image-button-view-folder currently-viewed-image-button"
						onClick={() => imageClicked("")}
					>
						<div className="view-image-in-folder-container">
							<img src={imageToView} className="view-image-in-folder" />
							<button
								className="view-image-in-folder-close-button"
								onClick={() => imageClicked}
							>
								&times;
							</button>
						</div>
					</button>
				) : (
					<p></p>
				)}
			</div>
			{/* <ButtonBoxComponent
				currentPage={"myllanFoto"}
				className="footer-margin"
				style={{flexDirection: "row"}}
			/> */}
		</div>
	);
};

export default ViewFolder;
