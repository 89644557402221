import React, { useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

const CreatePhotoFolder = () => {
	const [createdFolder, createNewFolder] = useState({
		name: "",
		description: "",
		tags: "",
		date: new Date(),
		createdBy: "Johan Ericsson", //Temp
		price: "",
		previewImage: ""
	});

	const handleNameChange = e => {
		createNewFolder({
			...createdFolder,
			name: e.target.value
		});
	};

	const handleDescriptionChange = e => {
		createNewFolder({
			...createdFolder,
			description: e.target.value
		});
	};

	const handleTagChange = e => {
		createNewFolder({
			...createdFolder,
			tags: e.target.value
		});
	};

	const handleDateChange = e => {
		createNewFolder({
			...createdFolder,
			date: e
		});
	};

	const handlePriceChange = e => {
		createNewFolder({
			...createdFolder,
			price: e.target.value
		});
	};

	const fileChosen = e => {
		createNewFolder({
			...createdFolder,
			previewImage: e.target.files[0]
		});
	};

	const addButtonClicked = e => {
		e.preventDefault();
		let retTags = createdFolder.tags.split(" ");
		let data = new FormData();
		data.append("name", createdFolder.name);
		data.append("description", createdFolder.description);
		data.append("tags", retTags);
		data.append("date", createdFolder.date);
		data.append("createdBy", createdFolder.createdBy);
		data.append("price", createdFolder.price);
		data.append("previewImage", createdFolder.previewImage);

		axios
			.post("https://react-myllan-frontend.herokuapp.com/photoFolders", data)
			.then(response => {
				console.log(response);
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<div>
			<h3>Skapa en ny folder</h3>
			<form>
				<input
					type="text"
					value={createdFolder.name}
					onChange={handleNameChange}
					placeholder={"Lägg till namn"}
				/>
				<textarea
					placeholder={"lägg till beskrivning"}
					onChange={handleDescriptionChange}
					value={createdFolder.description}
				/>
				<textarea
					placeholder={"lägg till taggar..."}
					onChange={handleTagChange}
					value={createdFolder.tags}
				/>
				<DatePicker selected={createdFolder.date} onChange={handleDateChange} />
				<input
					type="text"
					value={createdFolder.price}
					onChange={handlePriceChange}
					placeholder="lägg till pris"
				/>
				<input type="file" onChange={fileChosen} />

				<button onClick={addButtonClicked}>Lägg till</button>
			</form>
		</div>
	);
};

export default CreatePhotoFolder;
