import React from "react";
// import { SocialIcon } from "react-social-icons";

import ButtonBoxComponent from "./ButtonBoxComponent";
import "../styles/CyklandeVannerStyles.css";

const CyklandeVanner = () => {
	return (
		<div className="cyklande-vanner-main">
			<div className="cyklande-vanner-content">
				<div className="cyklande-vanner-textsection">
					<h1>Cyklande vänner</h1>
					<p className="cyklande-vanner-infotext">
						Vi är med och driver Cyklande vänner som är en grupp av cyklister.
						Till gruppen är alla välkomna oavsett nivå och ambition med sitt
						cyklande.
					</p>
					<p className="cyklande-vanner-infotext">
						Gruppen håller kontakt via Facebook och träffas då och då för
						gemensam träning eller andra aktiviteter. Bland annat åker vi
						Halvvättern och Vätternrundan tillsammans.
					</p>
					<a
						href="https://www.facebook.com/groups/405463492983384/"
						target="_blank"
					>
						<img
							src="small-facebook-icon-2.jpg"
							alt="För mer information besök vår facebook.grupp"
							className="facebook-icon"
						/>
					</a>
				</div>
				<img
					src="https://i.imgur.com/in88Tny.png"
					className="cyklande-vanner-foto"
				/>
				<t className="cyklande-vanner-bildtext">Gruppbild inför Vätternrundan 2019</t>
				<div className="sponsor-list-main">
					<h2 className="sponsor-list-header">
						Följande aktörer är också med och stöttar Cyklande vänner
					</h2>
					<nav className="sponsor-list-items">
						<a
							href="https://www.original.se/"
							target="_blank"
							className="sponsor-list-item"
						>
							Kalas
						</a>
						<a
							href="https://koching.se/"
							target="_blank"
							className="sponsor-list-item"
						>
							Koching
						</a>
						<a
							href="http://www.soft-office.se/"
							target="_blank"
							className="sponsor-list-item"
						>
							Soft Office Dalarna
						</a>
						<a
							href="http://www.ostgotahusbilsuthyrning.se/"
							target="_blank"
							className="sponsor-list-item"
						>
							Östgöta husbilsuthyrning
						</a>
					</nav>
				</div>
			</div>
			<ButtonBoxComponent currentPage={"cyklandeVanner"} style={{ display: "block" }} />
		</div>
	);
};

export default CyklandeVanner;
