import React from "react";
import { NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";

import "../styles/HeaderStyles.css";

const Header = () => {
	return (
		<div className="main-header">
			<div className="header-content">
				<div className="header-image">
					<NavLink to="/" exact={true} className="header-buttons">
						<img
							src="forslag_logga_13.jpg"
							className="header-logo"
							alt="Myllan"
						/>
					</NavLink>
				</div>
				<div className="header-buttons-box">
					{/* <NavLink to="/news" exact className="header-buttons">
						Nyheter
					</NavLink> */}
					<MediaQuery maxDeviceWidth={1223}>
						<NavLink to="/contact" className="header-buttons">
							Kontakt
						</NavLink>
					</MediaQuery>{" "}
					<NavLink to="/myllanfoto" className="header-buttons">
						Foto
					</NavLink>
					{/*<NavLink to="/fotoarkiv" className="header-buttons">
						Fotoarkiv
					</NavLink>*/}
					<NavLink to="/myllanIT" className="header-buttons">
						IT
					</NavLink>
					<MediaQuery minDeviceWidth={1224}>
						<NavLink to="/contact" className="header-buttons">
							Kontakta oss
						</NavLink>
					</MediaQuery>
				</div>
			</div>
		</div>
	);
};

export default Header;

{
	/* 			Fungerande header med nya loggan

	const Header = () => {
	return (
		<div className="main-header">
			<div className="header-content">
				<div className="header-image">
					<NavLink to="/" exact={true} className="header-buttons">
						<img
							src="forslag_logga_14.jpg"
							className="header-logo"
							alt="Myllan"
						/>
					</NavLink>
				</div>
				<div className="header-buttons-box">
					<NavLink to="/news" exact className="header-buttons">
						Nyheter
					</NavLink>
					<MediaQuery minDeviceWidth={1224}>
						<NavLink to="/contact" className="header-buttons">Kontakta oss</NavLink>
						</MediaQuery>
						<MediaQuery maxDeviceWidth={1223}>
						<NavLink to="/contact" className="header-buttons">Kontakt</NavLink>
						</MediaQuery>{" "}
				</div>
			</div>
		</div>
	);
}; */
}
