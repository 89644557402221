import React, { useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

const News = () => {
	const [createdNews, addNewsInfo] = useState({
		title: "",
		text: "",
		description: "",
		tags: "",
		date: new Date(),
		author: "Johan Ericsson", //Temp
		uploadedPhoto: "",
		photoText: ""
	});

	const handleTitleChange = e => {
		addNewsInfo({
			...createdNews,
			title: e.target.value
		});
	};

	const handleTextChange = e => {
		/* const regexTest = new RegExp('\n\n') // Kollar om det är två blankrader => nytt stycke
        if(regexTest.test(e.target.value)) {
            let currentText = createdNews.text
            
        } */
		addNewsInfo({
			...createdNews,
			text: e.target.value
		});
	};

	const handeDescriptionChange = e => {
		addNewsInfo({
			...createdNews,
			description: e.target.value
		});
	};

	const handleTagsChange = e => {
		addNewsInfo({
			...createdNews,
			tags: e.target.value
		});
	};

	const handleDateChange = e => {
		addNewsInfo({
			...createdNews,
			date: e
		});
	};

	const fileChosen = e => {
		addNewsInfo({
			...createdNews,
			uploadedPhoto: e.target.files[0]
		});
	};

	const handlePhotoTextChange = e => {
		addNewsInfo({
			...createdNews,
			photoText: e.target.value
		});
	};
	/* Verkar fungera utan detta just nu. Testa igen annars funkar den här för att splitta
    const fixTextToArray = (text) => {
        const regexTest = ('\n\n') // Kollar om det är två blankrader => nytt stycke
        let retTextArray = text.split(regexTest)
        return retTextArray
    }
    */

	const submitClicked = () => {
		console.log(createdNews);
		let retTags = createdNews.tags.split(" ");
		// let textArray = fixTextToArray(createdNews.text)
		let data = new FormData();
		data.append("title", createdNews.title);
		data.append("text", createdNews.text);
		data.append("description", createdNews.description);
		data.append("tags", retTags);
		data.append("date", createdNews.date);
		data.append("author", createdNews.author);
		data.append("photoName", createdNews.uploadedPhoto);
		data.append("photoText", createdNews.photoText);
		axios
			.post("http://localhost:5000/news/create", data)
			.then(response => {
				console.log(response);
				addNewsInfo({});
			})
			.catch(err => console.log(err));
	};

	return (
		<div>
			<h2>Create news</h2>
			<form>
				<input
					type="text"
					value={createdNews.title}
					onChange={handleTitleChange}
					placeholder="Lägg till titel"
				/>
				<textarea
					onChange={handleTextChange}
					placeholder="lägg till text"
					value={createdNews.text}
				/>
				<input
					type="text"
					value={createdNews.description}
					onChange={handeDescriptionChange}
					placeholder="add description"
				/>
				<input
					type="text"
					value={createdNews.tags}
					onChange={handleTagsChange}
					placeholder="Add tags"
				/>
				<DatePicker selected={createdNews.date} onChange={handleDateChange} />
				<input type="file" onChange={fileChosen} />
				<textarea
					onChange={handlePhotoTextChange}
					value={createdNews.photoText}
				/>
			</form>
			<button onClick={submitClicked}>Create news</button>
		</div>
	);
};

export default News;
