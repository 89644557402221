import React, { useState } from "react";

import SlideShowImageUpload from "./SlideShowImage";
import PhotoFolder from "./PhotoFolder";
import Photos from "./Photos";
import News from "./News";

// https://github.com/react-dropzone/react-dropzone

function AdminPage() {
	const [viewComp, changeViewComp] = useState("");

	const slideShowButtonClicked = () => {
		if (viewComp !== "slideShow") {
			changeViewComp("slideShow");
		} else {
			changeViewComp("");
		}
	};

	const photoFoldersButtonClicked = () => {
		if (viewComp !== "photoFolder") {
			changeViewComp("photoFolder");
		} else {
			changeViewComp("");
		}
	};

	const photosButtonClicked = () => {
		if (viewComp !== "photos") {
			changeViewComp("photos");
		} else {
			changeViewComp("");
		}
	};

	const newsButtonClicked = () => {
		if (viewComp !== "news") {
			changeViewComp("news");
		} else {
			changeViewComp("");
		}
	};

	return (
		<div>
			<h1>Admin page</h1>
			<button onClick={slideShowButtonClicked}>Slide show</button>
			<button onClick={photoFoldersButtonClicked}>Photo Folders</button>
			<button onClick={photosButtonClicked}>Photos</button>
			<button onClick={newsButtonClicked}>News</button>
			{viewComp === "slideShow" ? <SlideShowImageUpload /> : ""}
			{viewComp === "photoFolder" ? <PhotoFolder /> : ""}
			{viewComp === "photos" ? <Photos /> : ""}
			{viewComp === "news" ? <News /> : ""}
		</div>
	);
}

export default AdminPage;
