import React from "react";
import  "./../styles/ContactPageStyles.css";

import ButtonBoxComponent from "./ButtonBoxComponent";

const ContactPage = () => {
	return (
		<div className="contact-page-main">
			<div className="contact-page-content">
				<h1 className="contact-page-header">Kontakta oss</h1>
				<p className="contact-info-text">
					Om ni vill komma i kontakt med oss på Myllan så är ni varmt välkomna
					att kontakta oss enligt uppgifterna nedan:
				</p>
				<div className="contact-info-box">
					<p className="contact-info-box-sub-title">Kontaktperson</p>
					<p className="contact-info-box-text">Johan Ericsson</p>
					<p className="contact-info-box-text">johan.e@miljo.se</p>
					<p className="contact-info-box-text"> 070-194 95 04</p>
				{/*	<p className="contact-info-box-sub-title">Myllan</p> */}
					{/*<p>testvägen 123</p>
	<p>123 123 teststaden</p> */}
					<p className="contact-info-box-text-orgnr" >Org.nr: 556826-0649</p>
				</div>
			</div>
			<ButtonBoxComponent />
		</div>
	);
};

export default ContactPage;
