import React, { useState } from "react";
import axios from "axios";
import SeeAllFolders from "./SeeAllFolders";

const PhotoAlbums = () => {
	const [images, setImages] = useState([]);

	return (
		<div>
			<h2>Fotoalbum</h2>
			<SeeAllFolders />
		</div>
	);
};

export default PhotoAlbums;
