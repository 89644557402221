import React from "react";
import "./../styles/FooterStyles.css";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
	return (
		<footer>
			<div id="main-footer">
				<div className="footer-icons">
					<SocialIcon
						url="https://www.facebook.com/MyllanFoto"
						target="_blank"
                        className="soc-icon"
					/>
					<SocialIcon
						url="https://www.instagram.com/myllan_foto/"
						target="_blank"
                        className="soc-icon"

					/>
					<SocialIcon
						url="mailto:johan.e@miljo.se"
						target="_blank"
						bgColor="#EA4335"
                        className="soc-icon"

					/>
				</div>
				<p className="footer-copyright-text">&copy; Myllan 2022</p>
			</div>
		</footer>
	);
};

export default Footer;
