import React from "react";

import "./../styles/MyllanITStyles.css";
import ButtonBoxComponent from "./ButtonBoxComponent";

const MyllanIT = () => {
	return (
		<div className="myllan-IT-container">
			<div className="myllan-IT-text-section">
				<h1>Myllan IT</h1>
				<h2>
					Myllan IT är i en uppstartfas och arbetar bland annat med följande:
				</h2>
				<div className="myllan-IT-list">
					<p>
						Hemsidor - från idé till färdig hemsida. Vi kan hjälpa er att
						underhålla, förbättra och effektivisera er befintliga hemsida.
						Vidare är vi starka på idégivning, layout, utformning m.m.
					</p>
					<p>
						Appar - vi kan utveckla nya appar för olika tillämpningar eller
						önskemål
					</p>
					<p>
						Programmering och uteckling av mjukvara anpassat efter era behov
					</p>
					<p>Framtagning av produktblad, presentationer etc
					</p>
					<p>
						Examensarbeten - vi har kontakt med studerande som söker
						examensarbete och/eller möjlighet till projektarbete av olika slag.
						Hör av er till oss för diskussion och för att se vilken nytta detta
						kan göra för er.
					</p>
					<h3>Välkommen att höra av er till oss!</h3>
				</div>
			</div>
			<ButtonBoxComponent currentPage={"myllanIT"} className="footer-margin" />
		</div>
	);
};

export default MyllanIT;
