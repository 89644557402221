const initialState = [
	{
		// Blixt
		original: "https://i.imgur.com/tMBZk4B.jpg",
		thumbnail: "https://i.imgur.com/tMBZk4B.jpg",
	},
	{
		// Räv
		original: "https://i.imgur.com/AZloMwf.jpg",
		thumbnail: "https://i.imgur.com/AZloMwf.jpg",
	},
	{
		// L&L springer
		original: "https://i.imgur.com/ntHYTpY.jpg",
		thumbnail: "https://i.imgur.com/ntHYTpY.jpg",
	},
	{
		// Snötäckt berg
		original: "https://i.imgur.com/cXsspos.jpg",
		thumbnail: "https://i.imgur.com/cXsspos.jpg",
	},
	{
		// vatten
		original: "https://i.imgur.com/A1qso3U.jpg",
		thumbnail: "https://i.imgur.com/A1qso3U.jpg",
	},
	{
		//katarina-kyrka-natt
		original: "https://i.imgur.com/FxXVE70.jpg",
		thumbnail: "https://i.imgur.com/FxXVE70.jpg",
	},
	{
		// Olivoljor
		original: "https://i.imgur.com/NGEPrtA.jpg",
		thumbnail: "https://i.imgur.com/NGEPrtA.jpg",
	},
	{
		// Strandhorisont
		original: "https://i.imgur.com/TjkxUPR.jpg",
		thumbnail: "https://i.imgur.com/TjkxUPR.jpg",
	},

	{
		// Nån mops
		original: "https://i.imgur.com/4yug3u6.jpg",
		thumbnail: "https://i.imgur.com/4yug3u6.jpg",
	},
	{
		// Stella
		original: "https://i.imgur.com/bTrE9nu.jpg",
		thumbnail: "https://i.imgur.com/bTrE9nu.jpg",
	},
	{
		// Bilvrak
		original: "https://i.imgur.com/Oj6B2Nz.jpg",
		thumbnail: "https://i.imgur.com/Oj6B2Nz.jpg",
	},
	{
		// cykel-uppfor
		original: "https://i.imgur.com/CB5pBIF.jpg",
		thumbnail: "https://i.imgur.com/CB5pBIF.jpg",
	},
	{
		//Staket och Leia
		original: "https://i.imgur.com/rWSa5We.jpg",
		thumbnail: "https://i.imgur.com/rWSa5We.jpg",
	},
	{
		//Berg och stenar
		original: "https://i.imgur.com/Jwo0nCx.jpg",
		thumbnail: "https://i.imgur.com/Jwo0nCx.jpg",
	},
	{
		// Kloster hiss
		original: "https://i.imgur.com/W1otRpb.jpg",
		thumbnail: "https://i.imgur.com/W1otRpb.jpg",
	},
	{
		// Kloster tidvatten
		original: "https://i.imgur.com/HF7rHXN.jpg",
		thumbnail: "https://i.imgur.com/HF7rHXN.jpg",
	},
	{
		// grasstran
		original: "https://i.imgur.com/GgVV6Ju.jpg",
		thumbnail: "https://i.imgur.com/GgVV6Ju.jpg",
	},
	{
		// Cyklister i kurva tävling
		original: "https://i.imgur.com/iACOrZk.jpg",
		thumbnail: "https://i.imgur.com/iACOrZk.jpg",
	},
	{
		// Snökristaller
		original: "https://i.imgur.com/JFk6ewz.jpg",
		thumbnail: "https://i.imgur.com/JFk6ewz.jpg",
	},
	{
		// Whiskeyglas
		original: "https://i.imgur.com/LnI4ten.jpg",
		thumbnail: "https://i.imgur.com/LnI4ten.jpg",
	},
	{
		// Leia D i gräs
		original: "https://i.imgur.com/cUPaQKt.jpg",
		thumbnail: "https://i.imgur.com/cUPaQKt.jpg",
	},
	{
		//Solnedgång vatten
		original: "https://i.imgur.com/MW0EPaH.jpg",
		thumbnail: "https://i.imgur.com/MW0EPaH.jpg",
	},
	{
		// Ölflaska
		original: "https://i.imgur.com/8rnszqy.jpg",
		thumbnail: "https://i.imgur.com/8rnszqy.jpg",
	},
	{
		// Gullviva
		original: "https://i.imgur.com/RIgwJVR.jpg",
		thumbnail: "https://i.imgur.com/RIgwJVR.jpg",
	},
	{
		// Maskros
		original: "https://i.imgur.com/ANaCvi6.jpg",
		thumbnail: "https://i.imgur.com/ANaCvi6.jpg",
	},
	{
		// Träd
		original: "https://i.imgur.com/1bVkbPm.jpg",
		thumbnail: "https://i.imgur.com/1bVkbPm.jpg",
	},
	{
		// GRöna lund
		original: "https://i.imgur.com/FpdWKMV.jpg",
		thumbnail: "https://i.imgur.com/FpdWKMV.jpg",
	},

	{
		// Vita blommor
		original: "https://i.imgur.com/tRaogm0.jpg",
		thumbnail: "https://i.imgur.com/tRaogm0.jpg",
	},
	{
		// Träd kanalen
		original: "https://i.imgur.com/ioYCt28.jpg",
		thumbnail: "https://i.imgur.com/ioYCt28.jpg",
	},
	{
		// Cornelisparken
		original: "https://i.imgur.com/rKuJipe.jpg",
		thumbnail: "https://i.imgur.com/rKuJipe.jpg",
	},
	{
		// Sovande Kalleballe
		original: "https://i.imgur.com/mm6wA4m.jpg",
		thumbnail: "https://i.imgur.com/mm6wA4m.jpg",
	},
	{
		// KitKat
		original: "https://i.imgur.com/ngZwgm7.jpg",
		thumbnail: "https://i.imgur.com/ngZwgm7.jpg",
	},
];

export default function imageReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}

/** Per 2020-08-19
 * {
		//Ankor
		original: "https://i.imgur.com/xyqv2QP.jpg",
		thumbnail: "https://i.imgur.com/xyqv2QP.jpg",
	},
 */

/* Per 2020-08-03
	{
		//leia-spanar
		original: "https://i.imgur.com/5sYKbN5.jpg",
		thumbnail: "https://i.imgur.com/5sYKbN5.jpg"
	},
	{
		// grasstran
		original: "https://i.imgur.com/GgVV6Ju.jpg",
		thumbnail: "https://i.imgur.com/GgVV6Ju.jpg"
	},
	{
		// stad
		original: "https://i.imgur.com/mGKo7xE.jpg",
		thumbnail: "https://i.imgur.com/mGKo7xE.jpg"
	},
	{
		// original: "skugga_fagel.JPG"
		original: "https://i.imgur.com/1OurhbD.jpg",
		thumbnail: "https://i.imgur.com/1OurhbD.jpg"
	},
	{
		//skog-ljus
		original: "https://i.imgur.com/d79dB3J.jpg",
		thumbnail: "https://i.imgur.com/d79dB3J.jpg"
	},
	{
		//hotell-mallis
		original: "https://i.imgur.com/BLtXivR.jpg",
		thumbnail: "https://i.imgur.com/BLtXivR.jpg"
	},
	{
		// Vindkraftverk
		original: "https://i.imgur.com/kGPpKE6.jpg",
		thumbnail: "https://i.imgur.com/kGPpKE6.jpg"
	},
	{
		// kyrka-vatten
		original: "https://i.imgur.com/VIvQIet.jpg",
		thumbnail: "https://i.imgur.com/VIvQIet.jpg"
	},
	{
		//solnedgang
		original: "https://i.imgur.com/yJAj7Aw.jpg",
		thumbnail: "https://i.imgur.com/yJAj7Aw.jpg"
	},
	{
		//Skor
		original: "https://i.imgur.com/cuix1iV.jpg",
		thumbnail: "https://i.imgur.com/cuix1iV.jpg"
	},
	{
		// Vindsurfing
		original: "https://i.imgur.com/9Vii1aL.jpg",
		thumbnail: "https://i.imgur.com/9Vii1aL.jpg"
	},
	{
		// blommorosa
		original: "https://i.imgur.com/hWvazrU.jpg",
		thumbnail: "https://i.imgur.com/hWvazrU.jpg"
	},
	{
		// cykel-uppfor
		original: "https://i.imgur.com/CB5pBIF.jpg",
		thumbnail: "https://i.imgur.com/CB5pBIF.jpg"
	},
	{
		// rosor
		original: "https://i.imgur.com/5MWFDst.jpg",
		thumbnail: "https://i.imgur.com/5MWFDst.jpg"
	},
	{
		// lykke-ansikte
		original: "https://i.imgur.com/zGlyfDz.jpg",
		thumbnail: "https://i.imgur.com/zGlyfDz.jpg"
	},
	{
		//kyrka stående
		original: "https://i.imgur.com/UgBdzEB.jpg",
		thumbnail: "https://i.imgur.com/UgBdzEB.jpg"
	},
	{
		//katarina-kyrka-natt
		original: "https://i.imgur.com/FxXVE70.jpg",
		thumbnail: "https://i.imgur.com/FxXVE70.jpg"
	},
	{
		//hobal
		original: "https://i.imgur.com/XurCtCV.jpg",
		thumbnail: "https://i.imgur.com/XurCtCV.jpg"
	},
	{
		// anka-simmar
		original: "https://i.imgur.com/nPg3cmZ.jpg",
		thumbnail: "https://i.imgur.com/nPg3cmZ.jpg"
	},
	{
		// original: "gotakanalhost.jpg"
		original: "https://i.imgur.com/k0V27Y5.jpg",
		thumbnail: "https://i.imgur.com/k0V27Y5.jpg"
	},
	{
		//cykel-kurva
		original: "https://i.imgur.com/e20l5eN.jpg",
		thumbnail: "https://i.imgur.com/e20l5eN.jpg"
	},
	 {
		// original: "rod_fagel.jpg"
		original: "https://i.imgur.com/Xo6kcRJ.jpg",
		thumbnail: "https://i.imgur.com/Xo6kcRJ.jpg"
	},
	{
		// original: "maskros.JPG"
		original: "https://i.imgur.com/LSYqtbU.jpg",
		thumbnail: "https://i.imgur.com/LSYqtbU.jpg"
	},
	{
		// utsikt-hav
		original: "https://i.imgur.com/os4egWQ.jpg",
		thumbnail: "https://i.imgur.com/os4egWQ.jpg"
	} */
