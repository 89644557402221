import React from "react";

import ViewFolders from "./Reusable/ViewFolders";

const SeeAllFolders = () => {
	return (
		<div>
			{" "}
			<ViewFolders />
		</div>
	);
};

export default SeeAllFolders;
