import React, { useState, useEffect } from "react";
import axios from "axios";

import ViewFolder from "./ViewFolder";
import { Link } from "react-router-dom";

const ViewFolders = (props) => {
	const [folders, setFolders] = useState(props.albums);
	const [folderToView, setFolderToView] = useState(null);

	
/* 	useEffect(() => {
		let year = props.year
		console.log(props.year)
		axios
			//.get("https://react-myllan-frontend.herokuapp.com/photoFolders" , {params: {year: props.year}})
			.get(`http://localhost:5000/photoFolders/${year}`)

			.then((result) => {
				console.log(result.data);
				setFolders(result.data);
				console.log("CDM then");
			})
			.catch((err) => console.log(err));
	}, []); */

	const folderButtonClicked = (e) => {
		let folderId = e.target.value;
		folders.map((folder) => {
			if (folder._id === folderId) {
				if (folderToView === null) {
					setFolderToView(folder);
				} else {
					setFolderToView(null);
				}
			}
		});
	};

	return (
		<div>
			{folders !== null ? (
				<div className="preview-all-folders-section">
					<h2>Dessa album finns för närvarande tillgängliga</h2>
					<div className="preview-folders-box">
						{folders.map((folder) => {
							console.log(typeof folder.date);
							return (
								<div key={folder._id} className="preview-folder">
									<Link
										to={{
											pathname: `/view/${folder._id}`,
											state: {
												name: folder.name
											},
										}}
										className="preview-folder-link"
									>
										<div>
											<h1 className="preview-folder-header">{folder.name}</h1>
											<img
												className="folder-preview-image"
												src={`https://storage.googleapis.com/${folder.previewImage}`}
											/>
										</div>
									</Link>
								</div>
							);
						})}
					</div>
					{/* {folderToView !== null ? (
						<ViewFolder folderToView={folderToView} />
					) : (
						<p></p>
					)} */}
				</div>
			) : (
				<h3>you got no folders</h3>
			)}
		</div>
	);
};

export default ViewFolders;

/* 
class ViewFolders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			folders: null,
			viewFolder: false,
			folderToView: null
		};
		this.folderButtonClicked = this.folderButtonClicked.bind(this);
	}
	componentDidMount() {
		axios
			.get("http://localhost:5000/photoFolders")
			.then(result => {
				console.log(result.data);
				this.setState({ folders: result.data });
				console.log("CDM then");
			})
			.catch(err => console.log(err));
	}

	folderButtonClicked(e) {
		let folderId = e.target.value;
		this.state.folders.map(folder => {
			if (folder._id === folderId) {
				this.state.viewFolder === false
					? this.setState({ folderToView: folder, viewFolder: true })
					: this.setState({ viewFolder: false });
			}
		});
	}

	render() {
		return (
			<div>
				{this.state.folders !== null ? (
					<div>
						<h3>Dessa album finns för närvarande tillgängliga</h3>
						{this.state.folders.map(folder => {
							return (
								<button value={folder._id} onClick={this.folderButtonClicked}>
									{folder.name}
								</button>
							);
						})}
						{this.state.viewFolder !== false ? (
							<ViewFolder folderToView={this.state.folderToView} />
						) : (
							<p></p>
						)}
					</div>
				) : (
					<h3>you got no folders</h3>
				)}
			</div>
		);
	}
}

export default ViewFolders;


*/
