import React from 'react'
import "./../../styles/ViewImageStyles.css"


const ViewImage = (props) => {
    const src = props.src
    return (
        <div className="view-image-container">
            <img className="view-image" src={src}/>
        </div>
    )
}

export default ViewImage