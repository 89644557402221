import React, { Component } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import "../styles/ButtonBoxComponentStyles.css";

class ButtonBoxComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			myllanFoto: undefined,
			cyklandeVanner: undefined,
			myllanIT: undefined
		};
	}

	componentDidMount() {
		switch (this.props.currentPage) {
			case "myllanFoto":
				this.setState({
					myllanFoto: false,
					cyklandeVanner: true,
					myllanIT: true
				});
				break;
			case "cyklandeVanner":
				this.setState({
					myllanFoto: true,
					cyklandeVanner: false,
					myllanIT: true
				});
				break;
			case "myllanIT":
				this.setState({
					myllanFoto: true,
					cyklandeVanner: true,
					myllanIT: false
				});
				break;
			default:
				this.setState({
					myllanFoto: true,
					cyklandeVanner: true,
					myllanIT: true
				});
		}
	}
	render() {
		return (
			<div className="main-button-box-component">
				<div className="button-box-component-list-items">
					{this.state.myllanFoto === true ? (
						<Link to="/myllanfoto">
							<MediaQuery minDeviceWidth={1224}>
								{/*<p className="circle-text">Myllan Foto</p>*/}
								<img src="myllanfoto_1.png" className="myllan-logo-button" />
							</MediaQuery>
							<MediaQuery maxDeviceWidth={1223}>
								<img src="myllanfoto_1.png" className="myllan-logo-button" />
								{/* 								<button className="circle-button">
									<p className="circle-text">Foto</p>
								</button> */}
							</MediaQuery>
						</Link>
					) : (
						""
					)}
				</div>
				<div className="button-box-component-list-items">
					{this.state.cyklandeVanner === true ? (
						<Link to="/cyklandevanner">
							<button className="button-cyklande-vanner">
								<MediaQuery minDeviceWidth={1224}>
									<p className="button-cyklande-vanner-text">Cyklande vänner</p>
								</MediaQuery>
								<MediaQuery maxDeviceWidth={1223}>
									<p className="button-cyklande-vanner-text">Cyklande</p>
									<p className="button-cyklande-vanner-text">vänner</p>
								</MediaQuery>
							</button>
						</Link>
					) : (
						""
					)}
				</div>
				<div className="button-box-component-list-items">
					{this.state.myllanIT === true ? (
						<Link to="/myllanIT">
							<MediaQuery minDeviceWidth={1224}>
								{/* <p className="circle-text">Myllan IT</p> */}
								<img src="myllanIT_1.png" className="myllan-logo-button" />
							</MediaQuery>
							<MediaQuery maxDeviceWidth={1223}>
								<img src="myllanIT_1.png" className="myllan-logo-button" />
								{/* 								<button className="circle-button">
									<p className="circle-text">IT</p>
								</button> */}
							</MediaQuery>{" "}
						</Link>
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

export default ButtonBoxComponent;
