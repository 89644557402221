import React from "react";

import CreatePhotoFolder from "./CreatePhotoFolder";
import ViewFolders from "./../Reusable/ViewFolders";

class PhotoFolder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewTag: "null"
		};
	}
	createFolderClicked = () => {
		if (this.state.viewTag !== "createFolder") {
			this.setState({
				viewTag: "createFolder"
			});
		} else {
			this.setState({
				viewTag: "null"
			});
		}
	};
	viewFoldersClicked = () => {
		if (this.state.viewTag !== "viewFolders") {
			this.setState({
				viewTag: "viewFolders"
			});
		} else {
			this.setState({
				viewTag: "null"
			});
		}
	};
	render() {
		return (
			<div>
				<button onClick={this.createFolderClicked}>Create Folder</button>
				<button onClick={this.viewFoldersClicked}>View Folders</button>
				{this.state.viewTag === "createFolder" ? (
					<CreatePhotoFolder />
				) : (
					<p></p>
				)}
				{this.state.viewTag === "viewFolders" ? <ViewFolders /> : <p></p>}
			</div>
		);
	}
}

export default PhotoFolder;
