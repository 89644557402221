const uuidv1 = require("uuid/v1");

const initialState = [
	{
		title: "Jul- och nyårshälsning från oss",
		text: "Vi önskar våra kunder, samarbetspartners och övriga kontakter God Jul och Gott Nytt År!",
		media: "https://i.imgur.com/H839PuV.png"
	},
	{
		title: "Bästa cykelbilden 2020",
		text: "Hej vänner, det har varit ett märkligt (cykel)år med få tävlingar men kul att följa de som varit – dessa tävlingar har varit viktiga och det ska bli spännande att se vad som händer nästa år... */d ...innan nästa år får ni gärna så här i höstmörkret njuta och minnas lite samt avgöra vilken cykelbild som Myllan Foto lyckats fånga bäst. */d Bilderna kan ses på https://www.facebook.com/MyllanFoto"
	},
	{
		title: "Cykelhelg i Linköping",
		text: "Det var en fin cykelhelg i Linköping 25-26 september med Östgöta Motion,  Östgötatempot och Östgötaloppet. */d Myllan Foto var på plats båda dagarna och fångade många bilder. En del av dessa ligger ute på https://www.facebook.com/MyllanFoto och i dagarna kommer fler bilder läggas ut här på hemsidan.",
		id: uuidv1()
	},
	{
		title: "Myllan stöttar cykelsporten",
		text:
			"Vi lyfter gärna fram vikten av att röra på sig samt en god miljö – här passar cykelsporten väl in, en bra idrott för både motion och tävling. I år följer vi cykellaget Team Myllan i Postnord U6 Cycle Tour. */d Denna cykeltävling pågår under 6 dagar i juli sedan många år och här möts ungdomar, juniorer och elit från hela Sverige. Följ loppet på bland annat www.u6cycletour.se och https://www.facebook.com/u6cycletour/. */d Tanken är att Team Myllan ska vara ett projekt som utvecklas, resan har börjat och vi får se hur den fortsätter… ",
		// media: "teammyllan.jpg",
		media: "https://i.imgur.com/8savNjj.jpg",
		id: uuidv1()
	},
	{
		title: "Myllan foto på Facebook",
		text:
			"Nu finns Myllan Foto på Facebook! Välkommen att följa oss där på https://www.facebook.com/MyllanFoto/"
	},
	{
		title: "Myllan Foto på Wolfram GP",
		text:
			"Stadscykelloppet Wolfram GP genomfördes häromkvällen mitt i centrala Linköping. Det var ett starkt startfält, mycket publik och hård cykelåkning. Myllan foto följde tävlingen, se mer på http://ckhymer.com/dubbelseger-for-motala-i-wolfram-grand-prix/ och på https://www.facebook.com/MyllanFoto/",
		media: "https://i.imgur.com/w5m6UGZ.jpg",
		id: uuidv1()
	}
];

export default function newsReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}

// 			"Vi lyfter gärna fram vikten av att röra på sig samt en god miljö – här passar cykelsporten väl in, en bra idrott för både motion och tävling. I år följer vi cykellaget Team Myllan i Postnord U6 Cycle Tour. Denna cykeltävling pågår under 6 dagar i juli sedan många år och här möts ungdomar, juniorer och elit från hela Sverige. Följ loppet på bland annat www.u6cycletour.se och https://www.facebook.com/u6cycletour/. */d Tanken är att Team Myllan ska vara ett projekt som utvecklas, resan har börjat och vi får se hur den fortsätter… ",
