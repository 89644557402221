import React from 'react'

const HomePage = () => {
    return (
        <div>
            <h1>Home page</h1>
            <p>temp</p>
            <p>Kommer ersätta den nuvarande temporära startsidan</p>
        </div>
    )
}

export default HomePage;